import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import discord from './images/discord.png'
import github from './images/github.png'
import whalestream from './images/whalestream.png'

const NavBar = () => {
  return (
    <Navbar bg='dark' variant='dark'>
      <Container>
        <Navbar.Brand href='https://whalestream.com'>
          <img
            src={whalestream}
            className='d-inline-block align-top'
            width='28'
            height='28'
            alt='whalestream.com'
          />
        </Navbar.Brand>
        <Nav>
          <Nav.Link href='https://discord.gg/whalestream'>
            <img
              src={discord}
              className='align-top'
              width='28'
              height='28'
              alt='discord'
            />
          </Nav.Link>
          <Nav.Link href='https://github.com/jr0dd?tab=repositories'>
            <img
              src={github}
              className='align-top'
              width='28'
              height='28'
              alt='github'
            />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default NavBar
