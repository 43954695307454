// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  font-family: 'Poppins', sans-serif;\n}\n\n.App {\n  margin: 0 auto;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n}\n\nh1 {\n  letter-spacing: -0.025em;\n  font-weight: 700;\n  text-shadow: 1px 1px 2px black;\n}\n\n.bg-image {\n  background-repeat: no-repeat;\n  opacity: 0.2;\n  width: 70%;\n  height: auto;\n  align-self: center;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');\n\n* {\n  font-family: 'Poppins', sans-serif;\n}\n\n.App {\n  margin: 0 auto;\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n}\n\nh1 {\n  letter-spacing: -0.025em;\n  font-weight: 700;\n  text-shadow: 1px 1px 2px black;\n}\n\n.bg-image {\n  background-repeat: no-repeat;\n  opacity: 0.2;\n  width: 70%;\n  height: auto;\n  align-self: center;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
