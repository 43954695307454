import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios'
import './Bot.css'
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000'
const inviteLink = 'https://discord.com/oauth2/authorize?client_id=991947499449360445&permissions=1643093949527&scope=bot%20applications.commands'
const discord = 'https://static.wixstatic.com/media/8af083_56c62078c53b44e28661eb2457a529c8~mv2.png/v1/fill/w_515,h_161,al_c,usm_0.66_1.00_0.01/join-discord-png-10.png'

const Bot = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [stats, setStats] = useState([])

  useEffect(() => {
    const timer = setInterval(() => {
      const getStats = async () => {
        const res = await axios.get(`${baseUrl}/stats`, { headers: { Accept: 'application/json' } })
        const stats = res.data
        setStats(stats)
        setIsLoading(false)
      }
      getStats()
      clearInterval(timer)
    }, 2500)
  }, [stats])

  if (isLoading) {
    return <Spinner animation='grow' className='Bot-loading' />
  }

  return (
    <div className='Bot'>
      <div className='Bot-install'>
        <a href={inviteLink}><img src='https://www.pngmart.com/files/7/Install-PNG-File.png' alt='' /></a>
      </div>
      <div className='Bot-stats'>
        <p><b>Stats: </b></p>
        <ul>
          <li>Servers:
            <span><b>{stats.serverCount}</b></span>
          </li>
          <li>Users:
            <span><b>{stats.userCount}</b></span>
          </li>
          <li>Total Commands:
            <span><b>{stats.commands}</b></span>
          </li>
          <li>Created:
            <span><b>{stats.created}</b></span>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <div className='Bot-perf'>
        <p><b>Performance: </b></p>
        <ul>
          <li>Uptime:
            <span><b>{stats.uptime}</b></span>
          </li>
          <li>Memory:
            <span><b>{stats.memory}</b></span>
          </li>
          <li>Arch:
            <span><b>{stats.arch}</b></span>
          </li>
          <li>Platform:
            <span><b>{stats.platform}</b></span>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className='Bot-join'>
        <a href='https://discord.gg/whalestream'><img src={discord} alt='' /></a>
      </div>
    </div>
  )
}

export default Bot
