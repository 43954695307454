import React from 'react'
import Bot from './Bot'
import NavBar from './NavBar'
import './App.css'

const App = () => {
  return (
    <div className='App'>
      <NavBar />
      <br />
      <img
        src='https://whalestream-cdn.s3.us-east-2.amazonaws.com/logo/whalestream-logo-avatar.png'
        alt=''
        class='bg-image'
      />
      <h1>Whalestream Discord Bot!</h1>
      <Bot />
    </div>
  )
}

export default App
